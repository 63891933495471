<template>
  <NuxtPage />

</template>


<script>
import { defineComponent, ref } from "vue";
import AOS from 'aos';

export default defineComponent({
  name: "Event",
  mounted() {
    AOS.init({
      delay: '200'
    });
  },
});
</script>
