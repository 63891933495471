import { defineStore } from "pinia";
import axios from "axios";

const baseURLApi = process.env.NODE_ENV === 'production' ? 'https://api-guestifa-v2.tim-open.pro' : 'https://api-guestifa-v2.tim-open.pro';

const serverApi = axios.create({
  baseURL: baseURLApi,
});

const cors = {
  withCredentials: true,
  headers: {

  }
};
export const useMainStore = defineStore("main", {
  state: () => ({
    isAudioPlay: false,
    messages: [
      {
        guestName: 'Andika',
        guestStatus: true,
        guestMessage: 'Suscipit. Ut consectetuer leo vehicula augue. Aliquam cursus. Integer pharetra rhoncus.',
        timestamp: '2022-05-06 12:00'
      },
      {
        guestName: 'Rahmawati',
        guestStatus: true,
        guestMessage: 'Suscipit. Ut consectetuer leo vehicula augue. Aliquam cursus. Integer pharetra rhoncus.',
        timestamp: '2022-05-05 14:11'
      },
      {
        guestName: 'Sebastian',
        guestStatus: false,
        guestMessage: 'Suscipit. Ut consectetuer leo vehicula augue. Aliquam cursus. Integer pharetra rhoncus.',
        timestamp: '2022-05-05 11:00'
      }
    ]
  }),
  actions: {
    // data guest
    async getDataGuest(nobarcode:any) {
      try {
        const response = await serverApi.get("/guest/checkGuest/"+nobarcode, cors);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    // ucapan
    async getDataUcapan(idevent: any) {
      try {
        const response = await serverApi.post("/ucapan/", 
        {
          idevent: idevent
        },cors);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async saveUcapan(nama: any, ucapan: any, tanggal: any, idevent: any, idguest: any) {
      try {
        const response = await serverApi.post("/ucapan/save",
          {
            nama: nama,
            ucapan: ucapan,
            tanggal: tanggal,
            idevent: idevent,
            idguest: idguest
          }, cors);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async confirmKehadiran(namaKehadiran: any, statusKehadiran: any, jumlahTamu: any, idevent: any, idguest: any) {
      try {
        const response = await serverApi.post("/kehadiran/save",
          {
            namaKehadiran: namaKehadiran,
            statusKehadiran: statusKehadiran,
            jumlahTamu: jumlahTamu,
            idevent: idevent,
            idguest: idguest
          }, cors);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  },
});