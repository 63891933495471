import { default as pinktiVoZNH8vSMeta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/birthday/pink.vue?macro=true";
import { default as whitegreenKUbvZuTm1TMeta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/birthday/whitegreen.vue?macro=true";
import { default as blueflowerl0o8Oazah1Meta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/catalog/blueflower.vue?macro=true";
import { default as wedding_45whiteblack2JMm1AuTP3Meta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/catalog/wedding-whiteblack.vue?macro=true";
import { default as wedding_45whitebrownCNPeoVqtY8Meta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/catalog/wedding-whitebrown.vue?macro=true";
import { default as WeddinganVe3H3xfwMeta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/Wedding.vue?macro=true";
import { default as blueflower6GohN4GPJSMeta } from "/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/wedding/blueflower.vue?macro=true";
export default [
  {
    name: pinktiVoZNH8vSMeta?.name ?? "birthday-pink",
    path: pinktiVoZNH8vSMeta?.path ?? "/birthday/pink",
    meta: pinktiVoZNH8vSMeta || {},
    alias: pinktiVoZNH8vSMeta?.alias || [],
    redirect: pinktiVoZNH8vSMeta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/birthday/pink.vue").then(m => m.default || m)
  },
  {
    name: whitegreenKUbvZuTm1TMeta?.name ?? "birthday-whitegreen",
    path: whitegreenKUbvZuTm1TMeta?.path ?? "/birthday/whitegreen",
    meta: whitegreenKUbvZuTm1TMeta || {},
    alias: whitegreenKUbvZuTm1TMeta?.alias || [],
    redirect: whitegreenKUbvZuTm1TMeta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/birthday/whitegreen.vue").then(m => m.default || m)
  },
  {
    name: blueflowerl0o8Oazah1Meta?.name ?? "catalog-blueflower",
    path: blueflowerl0o8Oazah1Meta?.path ?? "/catalog/blueflower",
    meta: blueflowerl0o8Oazah1Meta || {},
    alias: blueflowerl0o8Oazah1Meta?.alias || [],
    redirect: blueflowerl0o8Oazah1Meta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/catalog/blueflower.vue").then(m => m.default || m)
  },
  {
    name: wedding_45whiteblack2JMm1AuTP3Meta?.name ?? "catalog-wedding-whiteblack",
    path: wedding_45whiteblack2JMm1AuTP3Meta?.path ?? "/catalog/wedding-whiteblack",
    meta: wedding_45whiteblack2JMm1AuTP3Meta || {},
    alias: wedding_45whiteblack2JMm1AuTP3Meta?.alias || [],
    redirect: wedding_45whiteblack2JMm1AuTP3Meta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/catalog/wedding-whiteblack.vue").then(m => m.default || m)
  },
  {
    name: wedding_45whitebrownCNPeoVqtY8Meta?.name ?? "catalog-wedding-whitebrown",
    path: wedding_45whitebrownCNPeoVqtY8Meta?.path ?? "/catalog/wedding-whitebrown",
    meta: wedding_45whitebrownCNPeoVqtY8Meta || {},
    alias: wedding_45whitebrownCNPeoVqtY8Meta?.alias || [],
    redirect: wedding_45whitebrownCNPeoVqtY8Meta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/catalog/wedding-whitebrown.vue").then(m => m.default || m)
  },
  {
    name: WeddinganVe3H3xfwMeta?.name ?? "Wedding",
    path: WeddinganVe3H3xfwMeta?.path ?? "/Wedding",
    meta: WeddinganVe3H3xfwMeta || {},
    alias: WeddinganVe3H3xfwMeta?.alias || [],
    redirect: WeddinganVe3H3xfwMeta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/Wedding.vue").then(m => m.default || m)
  },
  {
    name: blueflower6GohN4GPJSMeta?.name ?? "wedding-blueflower",
    path: blueflower6GohN4GPJSMeta?.path ?? "/wedding/blueflower",
    meta: blueflower6GohN4GPJSMeta || {},
    alias: blueflower6GohN4GPJSMeta?.alias || [],
    redirect: blueflower6GohN4GPJSMeta?.redirect,
    component: () => import("/Users/fahrezibayu/Documents/project/guestifa/guestifa-invitation/pages/wedding/blueflower.vue").then(m => m.default || m)
  }
]